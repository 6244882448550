import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import fondo from "./taxis.png";
// import Fondo from "./taxis.png";
const useStyles = makeStyles(() => ({
  layout: {
    width: 1450,
    margin: "auto",
    paddingTop: 80,
    paddingLeft: "20%",
  },
  layout2: {
    margin: "auto",
    paddingTop: 80,
    paddingLeft: 620,
  },
}));

export default function Home() {
  const classes = useStyles();
  //Hook useQuery
  return (
    <div className={classes.layout}>
      {/* <Typography variant="h4" gutterBottom>
        No hay viajes por mostrar
      </Typography> */}
      <img
        // src="https://freepngimg.com/thumb/joker/68949-joker-android-wallpaper-desktop-hd-image-free-png.png"
        src={fondo}
        alt="Fondo de la aplicacion Cliente"
        style={{
          width: "80%",
          height: "600px",
        }}
      />
    </div>
  );
}
