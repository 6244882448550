import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import VGS from "./assets/vgs.png";
//Screens

export default function AcercaDe() {
  const defaultProps = {
    bgcolor: "background.paper",
    m: 1,
    border: 1,
  };

  //Estilos
  const useStyles = makeStyles((theme) => ({
    appBar: {
      position: "relative",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 1200,
        height: 500,
        marginLeft: "auto",
        marginRight: "auto",
      },
      paddingBottom: 300,
      paddingTop: 300,
      paddingLeft: 150,
      display: "block",
      justifyContent: "center",
    },
    vgs: {
      display: "block",
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={2}>
        <Box borderRadius={16} borderColor="primary.main" {...defaultProps}>
          <Paper className={classes.paper}>
            <Grid item xs={12}>
              <img src={VGS} height={100} width={100} alt="Vigsoft" />
            </Grid>
            <Grid>
              <Typography className={classes.root}>
                <Link href="#">Link</Link>
              </Typography>
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}
