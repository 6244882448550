import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useQuery } from "react-query";
import { BASE_URL } from "../utils/index";
import Box from "@material-ui/core/Box";
import moment, { add } from "moment";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: 1450,
    margin: "auto",
    paddingTop: 80,
    paddingLeft: 220,
  },
  layout2: {
    margin: "auto",
    paddingTop: 80,
    paddingLeft: 620,
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

//GET
const fetchQuejas = async () => {
  let email2 = localStorage.getItem("email");
  let password2 = localStorage.getItem("password");
  let idCliente2 = localStorage.getItem("idCliente");
  let response = await fetch(`${BASE_URL}/quejasEnviadas`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email2,
      password: password2,
      idCliente: idCliente2,
    }),
  });

  return response.json();
};

export default function ViajesSolicitados() {
  const defaultProps = {
    bgcolor: "background.paper",
    m: 1,
    border: 1,
  };

  const classes = useStyles();
  //Hook useQuery

  const { data, isLoading, error, status, isFetching, refetch } = useQuery(
    "quejasEnviadas",
    fetchQuejas
  );

  if (isLoading)
    return (
      <React.Fragment>
        <div className={classes.layout}>
          <Typography variant="h6" color="inherit" noWrap>
            Cargando quejas...
          </Typography>
        </div>
        <LinearProgress />
      </React.Fragment>
    );

  if (error)
    alert(
      "Hubo un error al cargar los datos, intenta cerrando la app y comenzando de nuevo. Error: " +
        error.message
    );

  const columns = [
    {
      name: "idVP",
      label: "Número",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "primerApellido",
      label: "Primer Apellido",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "segundoApellido",
      label: "Segundo Apellido",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "servicio",
      label: "Servicio",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "estatus",
      label: "Estatus",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "fechaInicio",
      label: "Fecha inicio",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "pacientePrimero",
      label: "Primer paciente",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "NASS1",
      label: "NASS del primer paciente",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "pacienteSegundo",
      label: "Segundo paciente",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "NASS2",
      label: "NASS del segundo paciente",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "cliente",
      label: "Cliente",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    selectableRows: "none",
    print: false,
    downloadOptions: {
      filename: "QuejasEnviadasTaxisLleida.csv",
    },
  };

  if (status === "success" && data.quejas.length > 0) {
    return (
      <div className={classes.layout}>
        <Box borderRadius={16} borderColor="primary.main" {...defaultProps}>
          <MUIDataTable
            title={"Quejas"}
            data={data.quejas}
            columns={columns}
            options={options}
          />
        </Box>
      </div>
    );
  } else {
    return (
      <div className={classes.layout2}>
        <Typography variant="h4" gutterBottom>
          No se han enviado quejas
        </Typography>
      </div>
    );
  }
}
