import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// import moment, { add } from "moment";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  // DatePicker,
  // TimePicker,
  // DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import "date-fns";
import { ca } from "date-fns/locale";

//Estilos
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260,
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 510,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ViajeDetalles(props) {
  const classes = useStyles();
  const filter = createFilterOptions();

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Información del viaje
      </Typography>

      <Grid container spacing={3}>
        {props.values.perfilLog == 5 ? (
          <Grid item xs={12}>
            <FormControl className={classes.formControl2}>
              <Autocomplete
                value={props.values.idClienteSol}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    props.handleClientes(newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    props.handleClientes(newValue.inputValue);
                  } else {
                    props.handleClientes(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      nombre: `Usar: "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={props.values.clientes}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.nombre;
                }}
                renderOption={(option) => option.nombre}
                // style={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Escribe el Cliente que solicita"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.values.urgente}
                  onChange={(event) =>
                    props.handleUrgente(event.target.checked)
                  }
                />
              }
              label="Urgente Assignacion Directa al Frontal"
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <FormControl className={classes.formControl2}>
            <InputLabel id="demo-simple-select-label">
              Selecciona el tipo de vehículo
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.values.tipo}
              name="tipo"
              onChange={(e) => props.handleVehiculo(e.target.value)}
            >
              <MenuItem value={"TURISME"}>TURISME</MenuItem>
              <MenuItem value={"ADAPTAT"}>ADAPTAT</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl className={classes.formControl2}>
            <Autocomplete
              value={props.values.destino}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  props.handleDestino(newValue);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  props.handleDestino(newValue.inputValue);
                } else {
                  props.handleDestino(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Usar: "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={props.values.todosDestinos}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(option) => option.title}
              // style={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Escribe un pueblo destino"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl2}>
            <TextField
              required
              id="city"
              name="city"
              type="number"
              label="Cantidad se sesiones"
              fullWidth
              value={props.values.sesiones}
              onChange={props.handleData("sesiones")}
              defaultValue={props.values.sesiones}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl2}>
            {/*    <TextField
              required
              id="city"
              name="city"
              label="Centro de asistencia"
              fullWidth
              autoComplete="shipping address-level2"
              onChange={props.handleData("direccionHospital")}
              defaultValue={props.values.direccionHospital}
            /> */}
            <Autocomplete
              value={props.values.direccionHospital}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  props.handleCentros(newValue);
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  props.handleCentros(newValue.inputValue);
                } else {
                  props.handleCentros(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Usar: "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={props.values.todosCentros}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(option) => option.title}
              // style={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Escribe el Centro de Asistencia"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} container justify="space-around">
          <MuiPickersUtilsProvider utils={DateFnsUtils} local={ca}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Selecciona una fecha"
              format="dd/MM/yyyy"
              value={props.values.fecha}
              onChange={(date) => props.handleConfirm(date, "Fecha")}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Selecciona una hora"
              ampm={false}
              value={props.values.hora}
              onChange={(date) => props.handleConfirm(date, "Hora")}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <TextField
              id="city"
              name="city"
              disabled
              label="Seleccionaste:"
              fullWidth
              autoComplete="shipping address-level2"
              value={props.values.fechaMostrar}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        {props.activeStep !== 0 && (
          <Button onClick={props.prevStep} className={classes.button}>
            Regresar
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={props.nextStep}
          className={classes.button}
        >
          {props.activeStep === 4 && props.activeStep < 5
            ? "Solicitar viaje"
            : "Siguiente"}
        </Button>
      </div>
    </React.Fragment>
  );
}
