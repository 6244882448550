import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { set } from "date-fns";
import Exitoso from "../Exitoso";
import Error from "../Error";

import { BASE_URL } from "../../../utils/index";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function LoadingServicio(props) {
  useEffect(() => {
    const requestHandle = async () => {
      //   //   //Data
      const {
        tipo,
        direccionHospital,
        fechaCompletaEnviar,
        primerPaciente,
        puebloPrimerPaciente,
        direccionPrimerPaciente,
        telfPrimerPaciente,
        telfPrimerPaciente2,
        NASS1,
        segundoPaciente,
        puebloSegundoPaciente,
        direccionSegundoPaciente,
        telfSegundoPaciente,
        telfSegundoPaciente2,
        NASS2,
        destino,
        sesiones,
        email,
        password,
        idCliente,
        idClienteSol,
        perfilLog,
        idPaciente,
        idPaciente2,
        urgente,
      } = props.values;
      //   //Fetch
      // console.log(props.values);
      let response = await fetch(`${BASE_URL}/pedirViajeRadioterapia`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          fecha: fechaCompletaEnviar,
          tipo: tipo,
          primerPaciente: primerPaciente,
          puebloPrimerPaciente: puebloPrimerPaciente.title,
          telfPrimerPaciente: telfPrimerPaciente,
          telfPrimerPaciente2:
            telfPrimerPaciente2 != "" ? telfPrimerPaciente2 : "NO",
          direccionPrimerPaciente: direccionPrimerPaciente,
          NASS1: NASS1,
          segundoPaciente: segundoPaciente,
          puebloSegundoPaciente: puebloSegundoPaciente.title,
          telfSegundoPaciente: telfSegundoPaciente,
          telfSegundoPaciente2:
            telfSegundoPaciente2 !== "" ? telfSegundoPaciente2 : "NO",
          direccionSegundoPaciente: direccionSegundoPaciente,
          NASS2: NASS2,
          direccionHospital: direccionHospital.title,
          idCliente: perfilLog == 5 ? idClienteSol.id : idCliente,
          destino: destino.title,
          sesiones: sesiones,
          idPaciente: idPaciente,
          idPaciente2: idPaciente2,
          urgente: urgente,
        }),
      });
      // console.log(response);
      let response2 = await response.json();
      console.log(response2);
      const { message, id } = response2;
      if (message != undefined || message != null) {
        setStateComponent({
          ...stateComponent,
          isLoading: false,
          isSuccess: true,
          message: message,
        });
      } else {
        setStateComponent({
          ...stateComponent,
          isError: true,
        });
      }
    };
    requestHandle();
  }, []);

  const classes = useStyles();

  const [stateComponent, setStateComponent] = useState({
    isLoading: true,
    isSuccess: false,
    isError: false,
    message: "",
  });

  if (stateComponent.isLoading)
    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );

  if (stateComponent.isSuccess) {
    return <Exitoso message={stateComponent.message} />;
  }

  if (stateComponent.isError) {
    return <Error />;
  }
}
